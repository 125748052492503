import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpClientHelper} from '../app.httpclient';
import {AuthenticationHelper} from '../app.authentication';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class MobileViewService {

  private httpClient;
  private mobileViewUrlPrefix = 'api/mobileview/';

  constructor(httpClient: HttpClient, private httpHelper: HttpClientHelper, private authentication: AuthenticationHelper,
              private http: HttpClient) {
    this.httpClient = httpClient;
  }

  getUserFromToken(token: any) {
    const url = this.mobileViewUrlPrefix + 'user/' + token;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  setActionCardAsComplete(id) {
    const url = this.mobileViewUrlPrefix + 'action/complete/' + id;
    return this.httpHelper.put(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getActionCardList(personId: any) {
    const url = this.mobileViewUrlPrefix + 'action/list/' + personId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSurveyTrackingData(surveyTrackingId: any) {
    const url = this.mobileViewUrlPrefix + 'action/survey/' + surveyTrackingId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  setSurveyOpenedStatus(surveyTrackingId: any) {
    const url = this.mobileViewUrlPrefix + 'action/survey/opened/' + surveyTrackingId;
    return this.httpHelper.put(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getJourneyCardDeck(deckId: any) {
    const url = this.mobileViewUrlPrefix + 'journeyCard/deck/' + deckId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateJourneyCardDeckStatus ( data ) {
    const url = this.mobileViewUrlPrefix + 'journeyCard/deck/status';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: any) {
    return throwError(error);
  }

}
