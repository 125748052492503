import {AbstractControl} from '@angular/forms';

export class CharacterValidator {

  public static validate(c: AbstractControl) {
    const Character_REGEXP = /^[a-zA-Z\d_ ]*$/;

    return Character_REGEXP.test(c.value) ? null : {
      validateCharacter: {
        valid: false
      }
    };
  }
}
